import {
  GetPrItemDto,
  InventoryControllerGetDataAsList200Response,
  PurchaseRequestControllerGetDataAsList200Response,
} from "@api/api";
import { InventoryFormRule, InventoryFormSchema } from "@core/model/form.rule";
import {
  useQyDeleteInventory,
  useQyEditInventory,
  useQyEditManualInventory,
  useQyGetInventoryById,
  useQyGetInventoryStatus,
} from "@core/query/inventory.query";
import { getInventoryFormDefault } from "@core/model/get-form.default";
import { FieldErrors, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { SETTINGS } from "@core/utility/settings";
import { FormToApiService } from "@core/services/form-to-api.service";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { InventoryStatus } from "@core/model/inventory-status.enum";
import { useQueryClient } from "react-query";
import { QueryKey } from "@core/query/query-key.enum";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useGetRequestByIdQy } from "@core/query/request.query";
import { LabelValue } from "@shared/models/label-value.interface";

export function useEditMonitor() {
  const queryClient = useQueryClient();
  const { showError, showSuccess } = useNotificationContext();
  const { monitorId } = useParams();
  const componentRef = useRef(null);
  const icsPrintRef = useRef(null);
  const parPrintRef = useRef(null);
  const physicalPrintRef = useRef(null);
  const inventoryEquipmentRef = useRef(null);
  const propertyIssueRef = useRef(null);
  const physicalSemiExpendableRef = useRef(null);
  const [selectedPrint, setSelectedPrint] = useState(null);

  const [prItems, setPrItems] = useState<GetPrItemDto[]>([]);
  const navigate = useNavigate();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePrintIcs = useReactToPrint({
    content: () => icsPrintRef.current,
  });
  const handlePrintPar = useReactToPrint({
    content: () => parPrintRef.current,
  });
  const handlePrintPhysicalCount = useReactToPrint({
    content: () => physicalPrintRef.current,
  });
  const handlePrintInventoryEquipment = useReactToPrint({
    content: () => inventoryEquipmentRef.current,
  });
  const handlePrintPropertyIssue = useReactToPrint({
    content: () => propertyIssueRef.current,
  });
  const handlePrintSemiExpendable = useReactToPrint({
    content: () => physicalSemiExpendableRef.current,
  });
  const handleBack = () => {
    navigate("../");
  };
  const actionItems = [
    {
      label: "Register",
      command: () => {
        updateStatusAction(InventoryStatus.REG);
      },
    },
    {
      label: "ICS For Distribution",
      command: () => {
        updateStatusAction(InventoryStatus.ICSDIST);
      },
    },
    {
      label: "Damage",
      command: () => {
        updateStatusAction(InventoryStatus.DMG);
      },
    },
    {
      label: "Repair",
      command: () => {
        updateStatusAction(InventoryStatus.REPAIR);
      },
    },
    {
      label: "Lost",
      command: () => {
        updateStatusAction(InventoryStatus.LOST);
      },
    },
    {
      label: "Stolen",
      command: () => {
        updateStatusAction(InventoryStatus.STOLEN);
      },
    },
    {
      label: "Disposed",
      command: () => {
        updateStatusAction(InventoryStatus.DISPOSED);
      },
    },
    {
      label: "Print QR",
      command: () => {
        handlePrint();
      },
    },
    {
      label: "Delete",
      command: () => {
        updateStatusAction("DELETE");
      },
    },
  ];
  const printableItems = [
    {
      label: "ICS",
      value: "ICS",
    },
    {
      label: "PAR",
      value: "PAR",
    },
    {
      label: "Physical Count",
      value: "Physical Count",
    },
    {
      label: "Inventory Equipment",
      value: "Inventory Equipment",
    },
    {
      label: "Property Issue",
      value: "Property Issue",
    },
    {
      label: "Semi Expendable",
      value: "Semi Expendable",
    },
  ] as LabelValue[];

  const { data: statusResponse } = useQyGetInventoryStatus(
    "",
    99,
    0,
    undefined,
    undefined
  );

  // UNCACHED, GET API VALUES
  // GET REQUEST API
  const handleGetApiSuccess = (
    data: InventoryControllerGetDataAsList200Response
  ) => {
    if (data && data.count && data.count > 0) {
      const responseData = data.data?.[0];
      setValue("code", responseData?.code || "");
      setValue("batch", responseData?.batch || "");
      setValue("inventoryNo", responseData?.inventory_no || "");
      setValue("lot", responseData?.lot || "");
      setValue("office", responseData?.office || "");
      setValue("building", responseData?.building || "");
      setValue("endOfLife", responseData?.end_of_life || 0);
      setValue("assignee", responseData?.assignee || "");
      setValue("propertyType", responseData?.property_type || "");
      setValue("remarks", responseData?.remarks || "");
      setValue("status", responseData?.status || "");
      setValue("assignedDepartment", responseData?.department || "");
      setValue(
        "dateAssigned",
        responseData?.date_assigned
          ? (format(
              new Date(responseData?.date_assigned),
              SETTINGS.dateFormat
            ) as any)
          : undefined
      );
      setValue("poNo", responseData?.po_no || "");
      setValue(
        "poDate",
        responseData?.po_date
          ? (format(
              new Date(responseData?.po_date),
              SETTINGS.dateFormat
            ) as any)
          : undefined
      );
      setValue("poCategory", responseData?.po_category || "");
      setValue("procurementMode", responseData?.mode_of_procurement || "");
      setValue("resolutionNo", responseData?.resolution_no || "");
      setValue("iarNo", responseData?.iar_no || "");
      setValue("supplier", responseData?.supplier || "");
      setValue("supplierAddress", responseData?.supplier_address || "");
      setValue("supplierEmail", responseData?.supplier_email || "");
      setValue("supplierContact", responseData?.supplier_contact || "");
      setValue("supplierTin", responseData?.supplier_tin || "");
      setValue("prNo", responseData?.pr_no || "");
      setValue(
        "prDate",
        responseData?.pr_date
          ? (format(
              new Date(responseData?.pr_date),
              SETTINGS.dateFormat
            ) as any)
          : undefined
      );
      setValue("prCategory", responseData?.pr_category || "");
      setValue("prDepartment", responseData?.pr_department || "");
      setValue("prSection", (responseData as any)?.pr_section || "");
      setValue("prPurpose", (responseData as any)?.pr_purpose || "");
      setValue("itemCode", responseData?.pr_item_code || "");
      setValue("itemName", responseData?.item_name || "");
      setValue("itemPrice", responseData?.item_price || 0);
      setValue("unit", responseData?.unit || "");
      setValue("deliveryBrand", responseData?.delivery_brand || "");
      setValue("deliveryDescription", responseData?.delivery_description || "");
      setValue("parNo", responseData?.par_no || "");
      setValue("icsNo", responseData?.ics_no || "");
    }
  };
  const {
    data: inventoryResponse,
    isLoading,
    isError: inventoryError,
  } = useQyGetInventoryById(monitorId || "", handleGetApiSuccess);
  const inventoryData = inventoryResponse?.data?.[0];

  // API EDIT INVENTORY
  const handleUpdateApiSuccess = () => {
    showSuccess("Inventory item is updated successfully");
    queryClient.invalidateQueries([QueryKey.Inventory, monitorId]);
  };
  const { mutate: editInventory, isLoading: isEditLoading } =
    useQyEditInventory(handleUpdateApiSuccess);

  // API EDIT MANUAL INVENTORY
  const handleUpdateManualApiSuccess = () => {
    showSuccess("Inventory item is updated successfully");
    queryClient.invalidateQueries([QueryKey.Inventory, monitorId]);
  };
  const { mutate: editManualInventory } = useQyEditManualInventory(
    handleUpdateManualApiSuccess
  );

  // DELETE REQUEST API
  const handleDeleteApiSuccess = () => {
    showSuccess("Request is deleted successfully");
    handleBack();
  };
  const { mutate: deleteRequest, isLoading: isDeleting } = useQyDeleteInventory(
    handleDeleteApiSuccess
  );

  // GET PR ITEMS REQUEST API
  const handleGetRequestApiSuccess = (
    data: PurchaseRequestControllerGetDataAsList200Response
  ) => {
    if (!data || data.data?.length === 0) {
      return;
    }

    const responseData = data.data?.[0];
    setPrItems(responseData?.items || []);
  };
  useGetRequestByIdQy(
    inventoryData?.purchase_request || "",
    !!inventoryData?.purchase_request,
    handleGetRequestApiSuccess
  );

  const formMethod = useForm<InventoryFormSchema>({
    // CACHED / DEFAULT VALUES
    defaultValues: getInventoryFormDefault(inventoryData),
    resolver: zodResolver(InventoryFormRule),
  });
  const { handleSubmit, setValue, watch, getValues } = formMethod;
  const propertyType = watch("propertyType");
  const airNo = watch("airNo");
  const entityName = watch("entityName");

  const receivedFrom = watch("receivedFrom");
  const receivedFromPosition = watch("receivedFromPosition");
  const receivedFromDate = watch("receivedFromDate");
  const receivedBy = watch("receivedBy");
  const receivedByPosition = watch("receivedByPosition");
  const expenseCode = watch("expenseCode");
  const serialNo = watch("serialNo");

  const handleValidate = (form: InventoryFormSchema) => {
    if (!inventoryData) {
      throw new Error("No inventory data");
    }

    if (!inventoryData.purchase_request) {
      const formData = FormToApiService.EditManualInventory(
        form,
        inventoryData.code || ""
      );
      editManualInventory(formData);
    } else {
      const formData = FormToApiService.EditInventory(form, inventoryData);
      editInventory(formData);
    }
  };
  const handleValidateError = (err: FieldErrors<InventoryFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };

  const getStatusCode = (status: string) => {
    const filteredStatus = (statusResponse?.data || []).filter(
      (item) => item.name === status
    );
    const resultStatus =
      filteredStatus.length > 0 ? filteredStatus[0].code : null;
    return resultStatus;
  };
  const updateAction = () => {
    handleSubmit(handleValidate, handleValidateError)();
  };
  const updateStatusAction = (status: string) => {
    if (status === "DELETE" && !!inventoryData?.code) {
      deleteRequest({ code: inventoryData?.code });
      return;
    }

    const statusCode = getStatusCode(status);

    if (!statusCode) {
      showError(
        "Statuscode not found. Please refresh page and wait for it to load"
      );
      return;
    }

    setValue("status", statusCode);
    handleSubmit(handleValidate, handleValidateError)();
  };
  const assignAction = () => {
    updateStatusAction(InventoryStatus.ASSIGNED);
  };

  return {
    inventoryData,
    isLoading,
    inventoryError,
    formMethod,
    actionItems,
    isEditLoading,
    componentRef,
    icsPrintRef,
    parPrintRef,
    airNo,
    entityName,
    receivedFrom,
    receivedFromPosition,
    receivedFromDate,
    receivedBy,
    receivedByPosition,
    expenseCode,
    serialNo,
    physicalPrintRef,
    inventoryEquipmentRef,
    prItems,
    propertyIssueRef,
    physicalSemiExpendableRef,
    printableItems,
    selectedPrint,
    setSelectedPrint,
    handlePrintIcs,
    updateAction,
    assignAction,
    handleSubmit,
    setValue,
    watch,
    getValues,
    handlePrintPar,
    handlePrintPhysicalCount,
    handlePrintInventoryEquipment,
    handlePrintPropertyIssue,
    handlePrintSemiExpendable,
  };
}
